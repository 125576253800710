
  import { Component, Vue } from 'vue-property-decorator'
  import CourseEdit from './course/CourseEdit.vue'
  import CourseMember from './course/CourseMember.vue'

  @Component({ components: { CourseEdit, CourseMember } })
  export default class CourseSettings extends Vue {
    data: any[] = []

    activeName = 'settings'

    created() {
      for (let i = 1; i < 10; i++) {
        this.data.push({
          name: `course ${i}`
        })
      }
    }
  }
