
  import { Component, Vue } from 'vue-property-decorator'
  import CourseSettings from '../components/settings/CourseSettings.vue'

  @Component({ components: { CourseSettings } })
  export default class SettingsPage extends Vue {
    activeName = 'courses'

    handleClick(tab: any, event: Event) {
      console.log('tab', tab)
      console.log('event', event)
    }
  }
