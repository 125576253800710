import { render, staticRenderFns } from "./CourseSettings.vue?vue&type=template&id=2bc42d87&scoped=true&"
import script from "./CourseSettings.vue?vue&type=script&lang=ts&"
export * from "./CourseSettings.vue?vue&type=script&lang=ts&"
import style0 from "./CourseSettings.vue?vue&type=style&index=0&id=2bc42d87&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2bc42d87",
  null
  
)

export default component.exports