
  import { Component, Vue } from 'vue-property-decorator'

  @Component({ components: {} })
  export default class CourseMember extends Vue {
    data: any[] = []

    created() {
      for (let i = 1; i < 10; i++) {
        this.data.push({
          name: `member ${i}`
        })
      }
    }
  }
